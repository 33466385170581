@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$checkmarkMarginRight: .3rem;

.value {
  background-color: color("background");
  padding: size("gutter") size("gutter--large");
  border-top: 1px solid color("border--muted");
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: color("primary") !important;
    color: #fff !important;

    .checkmark {
      fill: #fff;
      stroke: #fff;
    }
  }

  &:first-child {
    border-top: none;
  }

  .checkmark {
    fill: color("primary");
    stroke: color("primary");
    position: absolute;
    right: calc(#{size("gutter--large")});
  }
}
