@use "sass:math";

@import "theme/ui-components/_variables.scss";

$headerHeight: size("header-height");
$miniCartMaxHeight: 495px;
$miniCartWidth: 312px;
$miniCartWidthMobile: 276px;
$miniHamburgerWidth: 25px;

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.3; }
}

@keyframes slideDown {
  0% { opacity: .7; transform: translateY(0); }
  100% { opacity: 1; transform: translateY($headerHeight); }
}

@keyframes fadeInSearch {
  0% { opacity: .7; }
  100% { opacity: 1; }
}

@keyframes fadeInBackButton {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.topBar {
  background-color: color("background--alt");
  height: 36px;
  width: 100%;
  max-width: 100vw;

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 size("wrapper-inset");
  }

  .link {
    margin-left: size("gutter--large");
    color: color("text");
  }
}

.block {
  z-index: z-index("system") + 2;
  background: #fff;
  height: size("header-height");
  position: absolute;
  width: 100%;
  display: flex;
  transition: top transition("duration--organisms") transition("ease-ease");
  will-change: top;
  padding: 0 size("wrapper-inset");
  border-bottom: 1px solid color("border--muted");
  max-width: 100vw;

  &.active {
    box-shadow: -1px 3px 15px 0 rgba(141,151,166,.16);
  }

  .hidden & {
    top: -size("header-height");
  }

  @media (max-width: breakpoint("medium")) {
    height: size("header-height--mobile");
  }
}

.item__logo {
  padding: 0 !important;
  margin-right: size("gutter");

  @media (min-width: breakpoint("medium")) {
    margin-right: size("gutter--large") * 1.5;
  }

  &:hover {
    text-decoration: none;
  }
}

.wrapper {
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.left {
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 1 0 auto;
  }
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 auto;
}

.item__search {
  display: flex !important;
  flex: 1 0 auto;
  align-items: center;
  animation:  fadeInSearch transition("duration--organisms") transition("ease-out") forwards;

  svg {
    height: 34px;
    width: 34px;
  }
}

.search + .left {
  order: -1;
}

.mobile {
   &.search + .left + .right {
    display: none;
  }

   &.search + .left {
    display: none;
  }
}

.nav {
  display: flex;
  align-items: stretch;
}

.right .nav {
  align-items: flex-end;
}

.item__customerService {
  svg {
    height: 30px;
  }
}

.links {
  display: flex;
}

.steps {
  color: color("border");

  @media (max-width: breakpoint("tiny-small")) {
    display: none;
  }
}

.step {
  margin-left: size("gutter");
  color: rgba(255, 255, 255, 0.6);

  &.active {
    text-decoration: underline;
    color: #fff;
  }
}

.item {
  color: #000;
  font-size: 1.125rem;
  text-decoration: none !important;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  display: flex;
  align-items: center;
  transition: border-color transition("duration--atoms") transition("ease-out");

  &:not(.item__logo) {
    margin: 0 1.32rem;

    @media (max-width: breakpoint("medium")) {
      margin: 0 0.8rem;
    }
  }

  span {
    margin-top: size("gutter--mini");
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  &__allproducts, &__categories, &__brands {
    color: color("text--dark");
    padding: .66rem 0;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      height: 3px;
      background-color: color("text--muted");
      width: 0;
      transition: width 200ms cubic-bezier(.4, 0, .2, 1);
    }

    &.active {
      &::before {
        background-color: color("primary");
        width: 100%;
      }
    }

    &:hover {
      color: color("text--muted");

      &::before {
        width: 100%;
      }

      .link__text {
        color: color("text--muted");
      }
    }
  }

  &__allproducts, &__categories, &__brands, &__customerService, &__search, &__points {
    @media (max-width: (breakpoint("medium") - 1px)) {
      display: none !important;
    }
  }
}

.back {
  animation: fadeInBackButton transition("duration--templates") transition("ease-out") forwards;
}

.back {
  text-transform: uppercase;
  font-weight: 700;
}

.categories, .brands {
  @media (min-width: breakpoint("medium")) {
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    z-index: z-index("system") + 1;
    animation:  slideDown transition("duration--organisms") transition("ease-out") forwards;
  }
}

.dim {
  position: fixed;
  top: size("header-height");
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system");
  background: #000;
  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
}

.miniCart {
  position: absolute;
  top: 6.8rem;
  left: auto;
  right: 6rem;
  width: $miniCartWidth;
  z-index: z-index("overlay");
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.1);
  border-radius: size("border-radius");
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @media (max-width: (breakpoint("small") - 1px)) {
    right: 5rem;
    width:$miniCartWidthMobile;
  }

  @media (min-width: (breakpoint("small"))) {
    max-height: calc(100svh - 120px - 2.5rem);
  }
}

.accountBadge {
  margin-right: math.div(size("gutter--mini"), 2);
}

.hamburger {
  min-width: $miniHamburgerWidth;
  margin: 0 -0.8rem;

  @media (min-width: breakpoint("medium")) {
    display: none;
  }
}

.desktopNavHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height");
  border-bottom: 1px solid color("border--muted");

  h3 {
    margin-bottom: 0;
  }

  svg {
    height: 30px;
    width: 30px;
  }
}

.subNav {
  background: color("OOC--color");

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (max-width: (breakpoint("medium") - 1px)) {
    min-height: 100vh;
  }

  display: none;

  @media (max-width: breakpoint("medium")) {
    left: 100%;
  }
}

.desktop {
  @media (max-width: (breakpoint("medium") - 1px)) {
    display: none !important;
  }
}

.mobile {
  @media (min-width: breakpoint("medium")) {
    display: none !important;
  }
}

:not(.mobile).subNav.search {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  background: none;
  position: relative;
  top: auto; right: auto; bottom: auto; left: auto;
}

.mode__categories .categories { display: block; }
.mode__brands .brands { display: block; }
.mode__search .search { display: block; }
.mode__language .language { display: block; }

.noMargin {
  margin: 0 !important;
}
