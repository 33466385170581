/*
  fonts.scss
  ------------------------------------------------------------

  Custom font faces goes here.

  Format them like this:

  @font-face {
    font-family: "Name";
    font-weight: 400;
    font-style: normal;
    src: url("../../static/assets/fonts/fontname.woff")  format("woff"),
         url("../../static/assets/fonts/fontname.woff2") format("woff2"),
         url("../../static/assets/fonts/fontname.ttf")   format("truetype");
  }

  Use the same family name but different font weights for
  different weights of the same family. In other words; DON'T
  name font faces with another weight as "name-regular",
  "name-bold" or similar. Just use the same name but a
    different weight.
*/


@font-face {
  font-family: "Metric";
  font-weight: 300;
  font-display: swap;
  src: url("assets/fonts/Metric-Regular.woff2") format("woff2"),
      url("assets/fonts/Metric-Regular.woff") format("woff"),
      url("assets/fonts/Metric-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Metric";
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/Metric-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Metric";
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/Metric-Semibold.woff2") format("woff2"),
    url("assets/fonts/Metric-Semibold.woff") format("woff"),
    url("assets/fonts/Metric-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Metric";
  font-weight: 700;
  font-display: swap;
  src: url("assets/fonts/Metric-Bold.woff2") format("woff2"),
    url("assets/fonts/Metric-Bold.woff") format("woff"),
    url("assets/fonts/Metric-Bold.otf") format("opentype");
}


.dummy_class_0 {
  font-family: "Metric";
  font-weight: 300;
}

.dummy_class_1 {
  font-family: "Metric";
  font-weight: 400;
}

.dummy_class_2 {
  font-family: "Metric";
  font-weight: 600;
}

.dummy_class_3 {
  font-family: "Metric";
  font-weight: 700;
}

