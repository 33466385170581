@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$facebook: #42599e;
$linkedIn: #117eb9;
$instagram: radial-gradient(circle farthest-corner at 35% 100%,#fec564,transparent 50%),radial-gradient(circle farthest-corner at 10% 140%,#feda7e,transparent 50%),radial-gradient(ellipse farthest-corner at 0 -25%,#5258cf,transparent 50%),radial-gradient(ellipse farthest-corner at 20% -50%,#5258cf,transparent 50%),radial-gradient(ellipse farthest-corner at 100% 0,#893dc2,transparent 50%),radial-gradient(ellipse farthest-corner at 60% -20%,#893dc2,transparent 50%),radial-gradient(ellipse farthest-corner at 100% 100%,#d9317a,transparent),linear-gradient(#6559ca,#bc318f 30%,#e42e66 50%,#fa5332 70%,#ffdc80 100%);

.block {
  background: color("footer");
  color: #fff;
  content-visibility: auto;
}

.wrapper {
  display: flex;
  justify-content: space-between;

  @media (max-width: breakpoint("small") - 1px) {
    flex-wrap: wrap;
  }
}

.col {
  padding: 0 size("gutter--large");

  @media (max-width: breakpoint("small") - 1px) {
    flex: 1 0 50%;
  }

  @media (max-width: breakpoint("tiny") - 1px) {
    flex: 1 0 100%;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-size: size("font") !important;
  margin-top: 0;
  color: color("text--light");
  text-transform: uppercase;
}

.link {
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  button {
    margin: 0 0 size("gutter--large");
  }
}

.logo {
  width: fit-content;
}

.categories {
  .link {
    margin: size("gutter--tiny") 0;
  }
}

.copyright {
  color: #fff;
  font-size: size("font--mini");
  margin: size("gutter");

  .socialmediaLinks {
    margin: size("gutter--large") 0;
    display: flex;
    justify-content: space-between;

  .link {
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: size("gutter--large");
      border: 1px solid color("dark") !important;
      border-radius: size("br") !important;
      fill: #a3a3a3;

      &:nth-child(1) {
        margin-left: 0;
      }

      &:focus, &:hover {
        fill: color("background--alt");

        .social {
          transform: scaleY(1);
        }
      }

      .social {
        display: flex;
        align-items: flex-start;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform transition("duration--organisms");
        border-radius: size("border-radius");

        &.facebook {
          background: $facebook;
        }

        &.linkedin {
          background: $linkedIn;
        }

        &.instagram {
          background: $instagram;
        }
      }
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    margin: 0 size("gutter--mini");

    &:hover {
      text-decoration: underline;
    }
  }
}

.legal {
  margin-top: size("gutter--large");
}

.contactInfo {
  margin-top: 0;
  color: #fff;
  font-size: size("font--small");
  font-style: italic;
}

.small {
  padding-top: size("gutter--large");
  @media (min-width: breakpoint("small")) {
    display: none;
  }

  @media (min-width: breakpoint("tiny")) {
    section:not(:first-child) {
      margin-top: size("gutter--large") * 1.5;
    }
  }

  @media (max-width: breakpoint("tiny") - 1px) {
    section {
      margin-top: size("gutter--large") * 1.5;
    }

    .col:first-child section {
      margin-top: 0;
    }
  }

  .copyright {
    margin-top: size("gutter--large");
  }
}

.large {
  padding: 4rem 0;

  @media (max-width: breakpoint("small") - 1px) {
    display: none;
  }
}

.bottomBar {
  padding: 2rem size("wrapper-inset");
  color: color("text--muted");
  background-color: color("dark");
}

.button {
  span {
    font-size: size("font--small") !important;
  }
}

.needHelp {
  color: color("text--light");
}

.helpLinks {
  .link {
    color: color("text--light");
    margin: size("gutter--large") size("gutter--large") 0 0;
  }
}