@import "theme/ui-components/_variables.scss";


.content {
  background: transparent;
  margin-top: 7px;
  text-align: center;

  &__left, &__right {
    top: 0;
    margin-top: 8px;
    width: 12px;
    height: calc(100% - 16px);
    position: absolute;
    background: inherit;
    border-radius: 50%;
  }

  &__left {
    margin-left: -2px;
    left: 0;
  }

  &__right {
    margin-right: -2px;
    right: 0;
  }
}
