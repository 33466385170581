@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


.block {
  color: color("text--muted");
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.link {
  color: color("text--muted");

  &:hover {
    color: color("primary");
    border-bottom-color: currentColor;
  }

  &:active:hover {
    opacity: .5;
  }
}

.current {
  display: inline-block;
  color: color("text--dark");
}

.hasVariant.white {
  color: #fff;

  .link {
    color: #fff;
  }

  .current {
    color: #fff;
  }
}