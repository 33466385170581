@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$spacing: size("gutter--large") * 3;
$manufacturerLogoWidth: 180px;
$manufacturerLogoHeight: 108px;

.wrapper {
  margin-top: -#{size("banner-height")};
}

.split {
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }

  @media (max-width: breakpoint("small") - 1px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.breadcrumbs {
  margin-bottom: size("gutter--large");
}

.header {
  &__outer {
    margin-bottom: size("gutter");
    display: block;

    @media (min-width: breakpoint("small")) {
      display: none;
    }
  }

  &__inner {
    display: flex;
    margin-bottom: size("gutter");
  }
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: size("gutter--large") * 1.5;
}

.name {
  display: block;
  font-size: 2.5rem;
  font-weight: bold;
  color: color("text--dark");
  margin-top: size("gutter--mini");
  margin-bottom: size("gutter--mini");
}

.brand {
  display: inline-block;
  color: color("text--muted");
  font-size: 2rem;
}

.goto {
  text-decoration: underline;
}

.left {
  @media (min-width: breakpoint("small")) {
    align-items: flex-start;
    padding: 0 math.div(size('grid-gutter'), 2);
    float: left;
    width: 50%;
    position: sticky;
    top: (size("header-height") + (math.div(size("header-margin"), 2)));
  }
}

.right {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size('grid-gutter'), 2);
    float: right;
    width: 50%;
    padding-left: $spacing;
  }
}

.qtyPicker,
.addToCart,
.login,
.options,
.options > *  {
  @media (max-width: breakpoint("small") - 1px) {
    width: 100%;
  }
}

.qtyPicker {
  margin-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    width: 135px;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  margin: size("gutter") 0;

  .price, .msrp {
    margin: 0;
  }

  .price {
    font-size: size("font--large");
    font-weight: bold;
  }
}

.discount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  min-height: 100px;
  min-width: 100px;
  color: #fff;
  background: color("primary");
  text-align: center;
  border-radius: 50%;
  user-select: none;

  span {
    font-size: 1.125rem;
    font-weight: bold;
  }

  strong {
    font-size: 2rem;
    margin-top: size("gutter--tiny");
  }
}

.gutter {
  padding-top: size("gutter");
}

.mainPayment {
  font-size: size("font--medium");
  margin: 0;
}

.secondaryPayment {
  display: block;
  margin-top: 0;
  font-size: size("font--small");
  color: color("text--muted");

  @media (max-width: breakpoint("small") - 1px) {
    margin-bottom: size("gutter") !important;
  }
}

.addToCartButton, .login {
  margin-top: size("gutter--large") !important;
  margin-bottom: size("gutter") !important;

  @media (max-width: breakpoint("tiny") - 1px) {
    width: 100%;
  }
}

.addToCartButton {
  width: 100%;
}

.addToCart h3 {
  font-family: #{font("body")};
  font-weight: bold;
  font-size: size("font");
  margin-bottom: size("gutter--mini");
}

.section {
  &Header {
    h2 {
      font-size: size("font--medium");
      color: color("text");
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
    }

    margin-top: size("gutter--large");
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding-bottom: size("gutter--small");
    border-bottom: 1px solid color("border--muted");

    svg {
      transition: transform transition("duration--organisms") transition("ease-ease");

      width: 24px;
      height: 24px;

      margin-right: size("gutter--small");

      .section__open & {
        transform: scaleY(-1);
      }
    }
  }

  &Body {
    padding: size("gutter") 0;
  }
}

.description {
  color: color("text--muted");

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: size("gutter");
  }

  li + li {
    margin-top: size("gutter--small");
  }

  .manufacturer {
    margin-top: size("gutter--large") * 1.5;

    .logo {
      max-width: $manufacturerLogoWidth;
      max-height: $manufacturerLogoHeight;
      display: inline-block;

      & img {
        max-width: $manufacturerLogoWidth;
        max-height: $manufacturerLogoHeight;
      }
    }
  }
}

.lists {
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }

  padding-top: size("gutter--large") * 2;

  .relatedList {
    width: 100%;

    @media (min-width: breakpoint("small")) {
      padding: 0 math.div(size('grid-gutter'), 2);
      float: left;
      width: 50%;
    }
  }

  .historyList {
    @media (min-width: breakpoint("small")) {
      padding: 0 math.div(size('grid-gutter'), 2);
      float: right;
      width: 50%;
    }

    @media (max-width: breakpoint("small") - 1px) {
      margin-top: size("gutter--large");
    }
  }
}

.hintWrapper {
  min-height: 100vh;
  margin-top: -#{size("banner-height")};
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummyContent {
  .top {
    height: size("gutter--large");
  }

  .middle {
    height: size("gutter--large");
    margin-top: size("gutter--large");
  }

  .bottom {
    margin-top: size("gutter--large");
    height: 200px;
  }

  .top, .middle, .bottom {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }
}

.customCloseIcon {
  path {
    fill: #000 !important;
  }
}
