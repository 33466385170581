@import "theme/ui-components/_variables.scss";

$view-transition-ease: transition("ease-ease");
$view-transition-duration: transition("duration--slow");

:export {
  viewDuration: $view-transition-duration;
}

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: breakpoint("page");
  margin: auto;
}

.top {
  flex: 1 0 auto;

  .onCheckout {
    padding-bottom: 0;
  }
}

.wrapper {
  background-color: #fff;

  &.onCheckout {
    background-color: initial;
  }
}

.footer {
  flex: 0 0 auto;
}

.notices {
  position: fixed;
  width: 100%;
  z-index: z-index("popup") + 1;
  box-shadow: 0 -6px 36px 0 rgba(0, 0, 0, 0.12);

  > div:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }
}

.notificationBar {
  background-color: color("accent");
  color: color("OOC--color");
  border: none;
}

:global {
  .leave {
    width: 100%;
    opacity: 1;
    position: absolute;
  }

  // .leave:not(.leaving) * {
  .leave * {
    animation: none !important;
    opacity: 1 !important;
  }

  .leaving {
    opacity: 0;
    transition: $view-transition-duration opacity $view-transition-ease;
  }

  .enter {
    opacity: 0;
  }

  .entering {
    opacity: 1;
    transition: $view-transition-duration opacity $view-transition-ease;
  }
}

.height {
  height: size("header-height");
  margin-bottom: size("header-margin") + size("banner-height");

  @media (max-width: breakpoint("medium")) {
    height: size("header-height--mobile");
  }
}
