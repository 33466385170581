@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.body {
  padding: size("gutter") size("gutter--large");
  background-color: color("background");

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.subheader {
  color: color("font");
  font-size: size("font");
}

.reset_button {
  color: color("text--muted");
}

.price_slider {
  padding: size("gutter") 8px;

  &__labels {
    display: flex;
    justify-content: space-between;
    color: color("text--muted");
  }
}

.price_inputs {
  padding-top: size("gutter--large");
  display: flex;
  align-items: center;

  .input {
    background-color: color("body");
  }

  & > div {
    flex: 1;

    &:first-child {
      padding-right: math.div(size("gutter"), 2);
    }

    &:last-child {
      padding-left: math.div(size("gutter"), 2);
    }
  }
}

.balance {
  padding: size("gutter--large") 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }

  .checkbox {
    margin-right: size("gutter--mini");
  }
}
