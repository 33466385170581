@import "../../node_modules/@crossroads/ui-components/src/ui-components/Carousel.scss";

.dot {
  border: none;

  &:not(.active) {
    padding: 10px;
  }

  &:not(.active):after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: color("border");
  }

  &.active {
    font-weight: bold;
    padding: 6px;
    background: color("primary");
  }
}

.prev, .next {
  color: color("border");
  padding: 1rem;
  border: 1px solid color("border");
  border-radius: 50%;

  svg {
    height: 18px;
    width: 18px;
  }
}

.dot {
  @media (max-width: breakpoint("tiny")) {
    margin-right: 0.5em;
  }
}
