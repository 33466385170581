@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/ProductViewMedia.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$item-width: 76px;
$image-max-width: 800px; // The product image size on the server is 800x80

.gallery {
  @media (min-width: breakpoint("small")) {
    display: flex;
    align-items: center;
    flex-direction: column;

    .block__bottom &, .block__top & {
      margin-left: -#{math.div(size("grid-gutter"), 2)};
      margin-right: -#{math.div(size("grid-gutter"), 2)};

      flex-wrap: wrap;
      flex-direction: row;
    }

    .block__left &, .block__right & {
      margin-top: -#{math.div(size("grid-gutter"), 2)};
      margin-bottom: -#{math.div(size("grid-gutter"), 2)};
      width: auto;
      flex: 0 0 auto;
    }
  }

  &Item {
    max-width: calc(#{$item-width} + #{size("grid-gutter")});
    padding-left: 0 !important;

    img {
      padding: math.div(size("grid-gutter"), 2) !important;
    }
  }
}

.lightbox {
  &Image {
    max-width: $image-max-width;
  }

  &Gallery {
    &Item {
      width: $item-width;
      height: $item-width;
    }
  }

  &Close {
    width: $item-width;
    height: $item-width;
  }

  .galleryItem {
    width: $item-width;
  }
}
