@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/ui-components/src/ui-components/CartMini.scss";

$width: 360px;
$img-size: 64px;
$header-height: size("header-height");

@keyframes appear {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.header {
  padding: size("gutter--mini") size("gutter--small") size("grid-gutter");
}

.miniCart {
  border: 2px solid color("primary");

  &::before, &::after {
    content: "";
    position: absolute;
    border-style: solid;
  }

  &::before {
    top: -1rem;
    right: calc(2rem - 2px);
    border-color: color("primary") transparent;
    border-width: 0 1rem 1rem;
  }

  &::after {
    top: calc(-1rem + 3px);
    right: 2rem;
    border-color: #fff transparent;
    border-width: 0 calc(1rem - 2px) calc(1rem - 2px);
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {

    right: size("gutter--small") !important;
    left: size("gutter--small") !important;
    width: auto !important;

    &::before {
      right: calc(6rem - 2px);
    }

    &::after {
      right: 6rem;
    }
  }
}

.itemQty {
  text-align: end;
  font-weight: 700;
}

.totalsRow {
  display: flex;

  &__grandtotal {
    padding-top: size("grid-gutter");
  }

  & + & {
    margin-top: size("grid-gutter");
  }
}

.bottom {
  button {
    width: 100%;
    border-radius: 20px/40px !important;
    span {
      padding-top: 3px;
    }
  }
}
