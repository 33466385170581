@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

$top-space: 35px;
$top-space--mobile: 25px;
$grid-gutter: size("gutter");
$maxWidthSmallWrapper: 546px;

.block {
  // negate app footer margin and apply here instead to keep background color
  padding-bottom: #{size("gutter--large") * 3};
  margin-bottom: -#{size("gutter--large") * 3};

  margin-top: -#{size("banner-height")};
}

.popularCategories {
  max-width: $maxWidthSmallWrapper;
  width: 100%;
  margin: auto;
  content-visibility: auto;

  @media (min-width: breakpoint("small")) {
    max-width: none;
  }

  & > * {
    width: 100%;
  }
}

.col2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -#{size("gutter--small")};

  @media (max-width: breakpoint("tiny") - 1px) {
    flex-direction: column;
    margin: 0;
  }
}

.column {
  flex: 0 0 auto;
  width: 50%;
  padding: size("gutter--small");

  @media (max-width: breakpoint("tiny") - 1px) {
    width: 100%;
    padding: 0;

    & + & {
      margin-top: size("gutter--small");
    }
  }
}

.blockHero {
  height: 180px;
  border-radius: size("border-radius");
  content-visibility: auto;

  @media (min-width: breakpoint("tiny")) {
    height: 420px;
  }

  @media (min-width: breakpoint("small")) {
    height: 480px;
  }
}

.content {
  padding-top: size("gutter");
}

.blockSplitHero {
  height: 180px;
  border-radius: size("border-radius");
  content-visibility: auto;

  @media (min-width: breakpoint("tiny")) {
    height: 340px;
  }

  @media (min-width: breakpoint("small")) {
    height: 545px;
  }
}

.brands {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.carousel {
  @include stretch(1rem);
}

.section {
  background-color: color("background--alt");
  padding: 2rem size("wrapper-inset");
  margin-top: 1rem;
}


.categoriesWrapper {
  padding: 2rem size("wrapper-inset");
}
.header {
  margin-bottom: size("gutter--large");

  h2 {
    color: color("text--dark");
    font-size: size("font--enormous")
  }
}

.categories {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  .col {
    flex: 1 0 25%;

    @media (max-width: (breakpoints("large") - 1px)) {
      flex: 1 0 50%;
    }

    padding: 15px;
  }

  .item {
    flex: 1 0 auto;
    height: 100%;
    display: block;
    text-align: center;
    background-color: #f5f5f5;
    transition: all .2s ease-in-out;
    padding: 2.5em;
    color: color("text--dark");

    &:hover {
      background-color: #e4e4e4;
      -webkit-transform: scale(1.02);
      transform: scale(1.02);
    }

    img {
      width: 100%;
    }

    h3 {
      font-weight: font("weight--bold");
      color: color("button--dark");
    }
  }
}
