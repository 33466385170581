@import "../../node_modules/@crossroads/shop-views/src/shop-views/DiscountCode";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$buttonHeight: 3rem;

.discountCode {
  input {
    height: $buttonHeight;
    line-height: $buttonHeight;
    padding-top: 5px;
  }

  button {
    height: $buttonHeight !important;
    border-radius: 20px/40px !important;
    padding-top: 3px !important;
  }
}
