@use "sass:math";

@import "theme/ui-components/_variables.scss";

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 570px;
}

.image {
  min-width: 100%;
  height: 100%;
  display: flex;

  img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    max-width: none;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      position: absolute;
    }
  }
}

.contentWrapper {
  position: absolute;
  margin: auto;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h1, h2 {
    color: #fff;
    margin: 0;
    white-space: pre-line;
    text-align: center;
    font-weight: 700;
  }

  h1 {
    font-size: size("font--enormous");
    line-height: 4.25rem;
    margin-bottom: size("gutter");
  }

  h2 {
    font-size: siz("font--huge");
  }

  @media (min-width: breakpoint("large")) {
    width: math.div(1, 3) * 100%;
  }

  @media (min-width: breakpoint("small")) {

    h1 {
      font-size: 4.875rem;
    }

    h2 {
      font-size: size("font--huge");
    }
  }

  & > *:not(a) + *:not(a) {
    margin-top: size("grid-gutter") !important;
  }

  .cta {
    margin-top: size("gutter--mini");

    @media (min-width: breakpoint("tiny")) {
      margin-top: size("gutter--small");
    }
  }
}
