@import "../../node_modules/@crossroads/shop-views/src/shop-views/CheckoutCart";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.summary {
  .submitWrapper {
    button {
      border-radius: 20px/40px !important;
      span {
        padding-top: 6px;
      }
    }
  }
}
