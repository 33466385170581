@import "theme/ui-components/_variables.scss";

.block {
  flex: 1 0 auto;

  display: flex;

  input {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    z-index: 6;
    height: 100%;
    font-size: 32px;
    text-align: center;
    background: transparent;
    border: none;
    cursor: text;
  }
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  height: 50px;
  border: 1px solid color("border");
  border-radius: size("border-radius");
  cursor: text;

  transition: background-color .24s,box-shadow .24s;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;

  &:not(:last-of-type) {
    margin-right: size("gutter--mini");
  }

  &Selected {
    box-shadow: rgba(58, 151, 212, 0.36) 0px 0px 0px 4px;
  }
}

