@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$pagination-btn-size: 2.5rem;

.block {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.empty {
  padding-top: size("gutter--large");
}

.paginationWrapper {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: size("gutter--large") 0;

  span {
    padding: 0 0.5rem;
  }

  &Button {
    display: flex;
    justify-content: center;
    align-content: center;
    background: none;
    color: color("primary");
    font-weight: bold;
    line-height: $pagination-btn-size;

    height: $pagination-btn-size;
    width: $pagination-btn-size;

    &[disabled] {
    }

    &.active {
      text-decoration: underline;
    }

    &:first-child {
      border-top-left-radius: $pagination-btn-size;
      border-bottom-left-radius: $pagination-btn-size;
    }

    &:last-child {
      border-top-right-radius: $pagination-btn-size;
      border-bottom-right-radius: $pagination-btn-size;
    }
  }
}
