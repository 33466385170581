@import "theme/ui-components/_variables.scss";

$padding: size("wrapper-inset");
$padding--small: 1.2rem;

.block {
  margin-left: auto;
  margin-right: auto;

  padding-left: $padding--small;
  padding-right: $padding--small;

  @media (min-width: breakpoint("medium")) {
    padding-left: $padding;
    padding-right: $padding;
  }

  &__thin {
    max-width: breakpoint("tiny");
  }
}
