@import "../../node_modules/@crossroads/shop-views/src/shop-views/Checkout";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.checkoutButton {
  border-radius: 20px/40px !important;
  padding-top: 6px !important;
}

.checkout label {
  padding-top: 5px;
}

.checkout input[type="checkbox"] + label {
  padding-top: 0;
}
