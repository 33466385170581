@import "theme/ui-components/_variables.scss";

.logoMain {
  display: flex;
  align-items: center;

  svg {
    width: auto;
    height: 84px;

    @media (max-width: breakpoint("medium")) {
      height: 40px;
    }
  }

  .suffix {
    text-transform: uppercase;
    font-size: 1.40625rem;
    margin-left: 1rem;
    margin-top: 9px;

    @media (max-width: breakpoint("medium")) {
      font-size: 1rem;
    }
  }
}
