@import "theme/ui-components/_variables.scss";

$delimeterMargin: .4rem;

.block {
  padding: 3rem 0 1rem;
}

.delimeter {
  display: inline-block;
  color: color("text");
  margin-left: $delimeterMargin;
  margin-right: $delimeterMargin;
}
