@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  headerHeight: strip-unit(size("header-height"));
}

.block {
  z-index: z-index("system");
}
