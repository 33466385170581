@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  small: strip-unit(breakpoint("small"));
}

.container {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
}

.containerServer {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  margin: 0 -#{size("gutter")};

  @media (max-width: breakpoint("small") - 1px) {
    & > div:nth-child(1n + 2) {
      display: none !important;
    }
  }

  @media (min-width: breakpoint("small")) and (max-width: breakpoint("medium") - 1px) {
    & > div:nth-child(1n + 3) {
      display: none !important;
    }
  }
}

.carouselControls {
  margin: size("gutter--large") 1rem 0;
}
