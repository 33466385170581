@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$filterbarHeight: size("filterbar-height");
$headerHeight: size("header-height");

:export {
  small: strip-unit(breakpoint("small"));
  filterbarHeight: strip-unit($filterbarHeight);
  headerHeight: strip-unit($headerHeight);
}

.block {
  background-color: color("background--alt");
}

.subCategories {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  min-height: 130px;

  li {
    padding: 1rem;
  }

  @media (max-width: breakpoint("medium") - 1px) {
    display: none;
  }
}

.dropdown {
  @media (min-width: breakpoint("medium")) {
    display: none;
  }
}