@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$length: 2.3rem;
$length-small: 1.6rem;
$dotLength: 6px;
$dotLength-small: 5px;
$color: #fff;
$duration: 6s;
$delay: $duration * .0285;
$spins: 5;
$allSpins: 360deg * $spins;

@keyframes spinner {
  0%    { transform: rotate(0);               opacity: 0; }
  25%   { transform: rotate($allSpins * .15); opacity: 1; }
  50%   { transform: rotate($allSpins * .50);             }
  75%   { transform: rotate($allSpins * .85); opacity: 1; }
  100%  { transform: rotate($allSpins);       opacity: 0; }
}

.block {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.wrap {
  width: $length;
  margin-left: auto;
  margin-right: auto;

  .small & {
    width: $length-small
  }
}

.dot {
  display: block;
  width: 100%;
  opacity: 0;
  animation: spinner $duration linear infinite;

  &:before {
    display: block;
    content: "";
    width: $dotLength;
    height: $dotLength;

    .small & {
      width: $dotLength-small;
      height: $dotLength-small;
    }

    background: $color;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:nth-child(2) {
    animation-delay: $delay;

    &:before {
      background: rgba($color, .8)
    }
  }

  &:nth-child(3) {
    animation-delay: $delay * 2;

    &:before {
      background: rgba($color, .6)
    }
  }

  &:nth-child(4) {
    animation-delay: $delay * 3;

    &:before {
      background: rgba($color, .4)
    }
  }

  &:nth-child(5) {
    animation-delay: $delay * 4;

    &:before {
      background: rgba($color, .2)
    }
  }
}

.primary .dot {
  &:before {
    background: rgba(color("primary"), 1);
  }

  &:nth-child(2):before {
    background: rgba(color("primary"), .8);
  }

  &:nth-child(3):before {
    background: rgba(color("primary"), .6);
  }

  &:nth-child(4):before {
    background: rgba(color("primary"), .4);
  }

  &:nth-child(5):before {
    background: rgba(color("primary"), .2);
  }
}
