@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  margin: size("gutter--section") 0;
}

.row {
  @include row();
}

.column {
  @include columns(1);

  @media (min-width: breakpoint("medium")) {
    @include columns(2);
  }
}

.title {
  color: color("text--dark");
  font-weight: font("weight--bold");

  @media (max-width: breakpoint("medium") - 1px) {
    padding-top: size("gutter");
  }
}

.manufacturer {
  color: color("text--muted");
  margin-top: size("gutter--tiny") !important;
}

.description {
  margin-bottom: size("gutter--medium");
}
