@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$imageWidthIncrease: 10px;

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  overflow: hidden;
  text-decoration: none;
  transition: border-color transition("duration--molecules") transition("ease-ease"),
              boxh-shadow transition("duration--molecules") transition("ease-ease");

  border: 1px solid color("border--muted");
  border-radius: size("border-radius");
  margin: 0 1rem;

  &:not(.dummy):hover {
    text-decoration: none;
    border-color: color("primary");

    .hoverFG {
      opacity: .2;
    }

    .name {
      text-decoration: underline;
    }
  }
}

.hoverFG {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  pointer-events: none;
  opacity: 0;
  transition: opacity transition("duration--molecules") transition("ease-ease"),
}

.header {
  overflow: hidden;
  background: color("disabled");
}

.imageWrapper {
  transition: transform transition("duration--molecules") transition("ease-ease");
}

.image {
  position: absolute;
  width: 100%;
}

.body {
  background: #fff;
  padding: size("gutter");
  text-align: center;
  // use static value to allow dummy cards
  // TODO: calculate in SASS?
  height: 240px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saleTag {
  height: 45px;
  width: 45px;
  color: #fff;
  background: color("primary");
  text-align: center;
  line-height: 48px;
  border-radius: 50%;
}

.brand {
  color: color("text--muted");
  margin: 0 0 size("grid-gutter") !important;
  font-size: size("font--large");

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--small");
  }
}

.description {
  color: color("text");
  margin: size("grid-gutter") 0 !important;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.name {
  font-weight: 700;
  color: color("text--dark");
  font-size: size("font--large");
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  line-height: font("line-height");
  width: calc(100% - #{size("gutter") * 2});
  margin-bottom: math.div(size("gutter--mini"), 2);

  @media (min-width: breakpoint("small")) {
    width: calc(100% - #{size("gutter") * 1.5});
  }

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--small");
  }
}

.priceWrapper {
  margin-top: size("gutter--mini");
}

.brand, .price, .msrp {
  line-height: 1;
}

.price, .msrp {
  font-size: size("font--small");
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--mini");
  }
}

.price {
  font-size: size("font--large");
  color: color("text");
  font-weight: bold;
}

.msrp {
  color: color("text--muted");
  margin-top: size("gutter--tiny");
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummy {
  .image {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  span, p {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .name {
    max-width: 50%;
  }

  .brand {
    max-width: 53%;
  }

  .price {
    max-width: 42%;
  }
}

.colors {
  display: flex;
  justify-content: center;
  position: absolute;
  top: size("gutter--mini");
  z-index: 1;
  left: 0;
  right: 0;

  & > div {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    margin: 0 math.div(size("gutter--mini"), 2);
  }
}
