@import "theme/ui-components/_variables.scss";

.button {
  height: 40px;
  padding: 20px;
  border-radius: 15px;
  font-weight: 700;
  font-size: 1.6em;
  z-index: 1;
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-left: 2px;
  margin-right: 2px;
  border: none;
  transition: none;

  &:not(.empty) {
    background-color: color("primary");

    .icon {
      fill: #fff;
    }

    &:hover {
      background-color: color("primary--hover");
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
}

.icon {
  width: 26px;
  height: 22px;
  fill: color("text");
}

.number {
  margin-right: size("gutter--small");
  font-size: size("font--medium");
  font-weight: font("weight--bold");
  color: #fff;
}
