
@use "sass:math";

@import "ui-components/_variables";
@import "ui-components/_mixins";
@import "../../node_modules/@crossroads/shop-views/src/shop-views/SuccessView.scss";

.wrapper {
  margin: 0 !important;
  padding: 0 size("wrapper-inset");
  background: color("body--off");
  width: 100%;
  max-width: none;
}

.checkIconBG {
  background-color: color("positive");
  padding: size("gutter--mini");
  border-radius: 50%;
  margin-right: size("gutter");
  
  svg {
    width: 24px;
    height: 24px;
  }
}

.mapText {
  margin-top: size("gutter");
  padding: size("grid-gutter");
}

.itemInfo {
  .name,
  .itemPrice {
    color: color("text")
  }
}

@media (min-width: breakpoint("small")) and (max-width: (breakpoint("medium") - 1px)) {
  .itemImage {
    width: 80px;
    height: 80px;
  }

  .itemPrice {
    display: none;
  }

  .itemInfo {
    padding: size("grid-gutter");
    grid-template-columns: minmax(60%,80%) minmax(0px,20%);
  }
}