@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$hintRowHeight: 250px;
$heroHeight: 360px;
$heroHeight_small: 240px;

.block, .blockHint {
  margin-top: -#{size("header-margin")};
  color: #fff;
  height: $heroHeight_small;

  &.short {
    background: transparent;
  }

  @media (min-width: breakpoint("medium")) {
    height: $heroHeight;
  }
}

.innerBlock {
  max-width: 1600px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}

.blockHint {
  min-height: $hintRowHeight;
}

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px;
  text-align: center;
  width: 33.33333%;

  .title {
    text-transform: initial;
    font-weight: 900;
    font-size: 4.875rem;
    line-height: 4.25rem;
  }

  .subTitle {
    font-size: size("font--medium");
  }

  @media (min-width: breakpoint("small")) {
    flex-direction: row;
  }
}

.subHero {
  background: #fff;

  .description {
    padding: 2rem;
    text-align: center;
    margin: auto;

    h2 {
      text-transform: initial;
      color: color("text--dark");
      margin-bottom: 0;
    }

    p {
      font-size: 1.125rem;
      margin-top: 0.5rem;
    }

    @media (min-width: breakpoint("large")) {
      max-width: 80ch;
    }
  }
}

.imageContainer {
  overflow: hidden;
  height: auto;
  width: auto;

  @media (min-width: breakpoint("small")) {
    position: absolute;
    top: 0;
    right: 0px;
    width: 100%;

    .gotImage & {
      height: 100%;
    }

    .image {
      height: auto;
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }
  }
}

.breadcrumbs {
  margin-bottom: size("gutter--large");
}

.delimeter {
  margin: 0 math.div(size("gutter--mini"), 2);
}
